@charset "utf-8";

// variables.less
$theme-primary: #c13e31;
$theme-primary-faded: #ad2a31;
$theme-dark: #666666;
$theme-dark-transparent: rgba(66, 65, 67, 0.35);
$theme-light: #ffffff;
$theme-light-faded: #eaeaea;
$highlight-colour: rgba(90, 90, 90, 0);

html {
  scroll-behavior: smooth;
}

ol, ul {
  color: #777;
}

.highlightable {
  color: $theme-light;
  text-shadow: -1px -1px 0 $theme-primary-faded,
  1px -1px 0 $theme-primary-faded,
  -1px 1px 0 $theme-primary-faded,
  1px 1px 0 $theme-primary-faded,
}

#down-arrow {
  color: $theme-light;
  text-shadow: -1px -1px 0 $theme-primary,
  1px -1px 0 $theme-primary,
  -1px 1px 0 $theme-primary,
  1px 1px 0 $theme-primary,
}

#footer {
  padding: 10vh 0;
}

hr {
  color: $theme-primary;
  margin: 2vh auto;
  opacity: 1;
  border-radius: 2px;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "mixins", "base";

.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 75%;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: $theme-light;
  background: $theme-dark;

  &.show {
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 1000ms;
  }

  a {
    text-decoration: underline
  }

  .acceptcookies {
    margin-left: 10px;
    vertical-align: baseline;
  }

}

.youtube-iframe {
  display:flex;
  justify-content:center;
  align-items:center;

  //iframe {
  //  max-height: 45vh;
  //}
}

.navbar {
  transition: all 0.4s linear;
  background: $theme-dark-transparent;
  border-bottom: 1px solid $theme-primary;

  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 700;

  &.navbar-solid {
    background-color: $theme-light;
    padding: 10px 1vw;
    opacity: 1;

    a {
      color: $theme-dark;

      &.navbar-brand {
        color: $theme-primary;
        font-size: 14pt;
      }
    }
  }

  a {
    color: $theme-light-faded;

    &:hover {
      color: $theme-primary;
    }

    &.navbar-brand {
      font-size: 12pt;
    }

    &.su-link {
      text-decoration: underline;
    }
  }
}
