html,
body {
  height: 100%;
  width: 100%;
}

body {
  @include serif-font;
}

hr {
  border-color: $theme-primary;
  border-width: 3px;
  max-width: 50px;
}

hr.light {
  border-color: white;
}

a {
  @include transition-all;
  color: $theme-primary;

  &:hover,
  &:focus {
    color: darken($theme-primary, 10%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.bg-primary {
  background-color: $theme-primary;
}

section {
  padding: 100px 0;
}

aside {
  padding: 50px 0;
}

.no-padding {
  padding: 0;
}

// Homepage Header
header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @include background-cover;
  background-position: center;
  background-size: cover;
  background-color: $theme-dark;
  filter: opacity(75%);
  text-align: center;
  color: $theme-light;

  .header-content {
    position: relative;
    text-align: center;
    width: 100%;

    .header-content-inner {
      .highlightable {
        text-shadow: 1px $highlight-colour;
      }

      h1 {
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
      }

      hr {
        margin: 30px auto;
      }

      p {
        font-weight: 300;
        color: rgba(white, .7);
        font-size: 16px;
        margin-bottom: 50px;
      }
    }
  }

  @media (min-width: 768px) {
    min-height: 100%;
    .header-content {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      padding: 0 50px;

      .header-content-inner {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;

        p {
          font-size: 18px;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

// Sections
.section-heading {
  margin-top: 0;
}

.service-box {
  max-width: 400px;
  margin: 50px auto 0;
  @media (min-width: 992px) {
    margin: 20px auto 0;
  }

  p {
    margin-bottom: 0;
  }

  .btn {
    color: $theme-primary;
  }

  &.sponsor {
    background-size: contain;
      img {
        max-height: 200px;
        height: 50%;
        max-width: 100%;
      }
  }

}

.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
  padding: .25rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 25px;

  .portfolio-box-caption {
    color: white;
    opacity: 0;
    display: block;
    background: rgba($theme-primary, .9);
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    @include transition-all;

    .portfolio-box-caption-content {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .project-category,
      .project-name {
        @include sans-serif-font;
        padding: 0 15px;
      }

      .project-category {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
      }

      .project-name {
        font-size: 18px;
      }
    }
  }

  &:hover {
    .portfolio-box-caption {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .portfolio-box-caption {
      .portfolio-box-caption-content {
        .project-category {
          font-size: 16px;
        }

        .project-name {
          font-size: 22px;
        }
      }
    }
  }
}

.call-to-action {
  h2 {
    margin: 0 auto 20px;
  }
}

// Bootstrap Overrides
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

// Button Styles
.btn-default {
  @include button-variant($theme-dark, white, white);
}

.btn-primary {
  @include button-variant(white, $theme-primary, $theme-primary);
}

.btn {
  @include sans-serif-font;
  border: none;
  border-radius: 300px;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-xl {
  padding: 15px 30px;
}

// Contact
#contact .fa {
  color: $theme-dark;
  font-size: 4em;
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

::selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}

body {
  -webkit-tap-highlight-color: $theme-dark;
}


.page-content {
  padding: 2em;

  h1 {
    text-transform: uppercase;
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    text-align-all: left;
    color: #5a5a5a;
    padding: 1em 0;
  }

  img {
    align-content: center;
    padding: 2em 1em;
    width: 100%;
    height: auto;
  }

  iframe {
    align-content: center;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-width: 80%;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}
